import {useContext, useMemo, useState} from 'react'
import TablaDinamica from '../../TablaDinamica'
import {EncabezadoFondo} from '../../Tablas'
import {CheckboxRegenerarCaja} from '../../inputs'
import moment from 'moment'
import {CeldaConTooltipPersonalizable} from '../../Celdas'
import {ContextApplication} from '../../../contexto'

export default function TablaRegenerarCierre({
  datosCierreCaja,
  actualizarSeleccionado,
}) {
  const splitArchivo = (urlArchivo) => {
    if (urlArchivo) {
      const splitArchivo = urlArchivo.split('\\')
      const url = splitArchivo[splitArchivo.length - 1].replace(/"/g, '')
      return url
    }
    return ''
  }

  const [usuariosRecepcion, setUsuarioRecepcion] = useState('')

  const contextoAplicacion = useContext(ContextApplication)

  const nombreRecepcionista = (data) => {
    const usuario = contextoAplicacion.usuariosRecepcion.find(
      (usuario) => usuario.correo === data
    )
    setUsuarioRecepcion(usuario?.nombre ?? '')
    return usuario?.nombre ?? ''
  }

  const columnas = useMemo(
    () => [
      {
        Header: () => 'Cierre de caja a regenerar',
        id: 'checkbox',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
        Cell: CheckboxRegenerarCaja,
      },
      {
        Header: () => 'ID de Cierre',
        accessor: 'id',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          return (
            row.original.id !== 0 && (
              <p className="text-cendiatra-gris-1 text-13px">
                {row.original.id}
              </p>
            )
          )
        },
      },
      {
        Header: () => 'Fecha y hora del cierre de caja',
        accessor: 'fecha',
        className:
          'w-3/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          const fecha = moment(row.original.fecha)
          const formattedFecha =
            fecha.isValid() && !row.original.id && !row.original.fechaSolicitud
              ? fecha.format('DD/MM/YYYY')
              : fecha.format('DD/MM/YYYY hh:mm A')
          return (
            <p className="text-cendiatra-gris-1 text-13px">{formattedFecha}</p>
          )
        },
      },

      {
        Header: () => 'Fecha y hora de solicitud',
        accessor: 'fechaSolicitud',
        className:
          'w-3/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          const fechaSolicitud = moment(row.original.fechaSolicitud)
          const formattedFechaSolicitud = fechaSolicitud.isValid()
            ? fechaSolicitud.format('DD/MM/YYYY hh:mm A')
            : ''
          return (
            <p className="text-cendiatra-gris-1 text-13px">
              {formattedFechaSolicitud}
            </p>
          )
        },
      },
      {
        Header: () => 'Usuario',
        accessor: 'nombreRecepcionista',
        className:
          'w-5/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) => {
          return (
            <span className="truncate block w-64">
              <CeldaConTooltipPersonalizable
                align={'justify-center'}
                texto={
                  nombreRecepcionista(row.original.nombreRecepcionista) ||
                  row.original.nombreRecepcionista
                }
                estilosPersonalizados="bg-cendiatra text-white rounded-md p-1 -mt-14"
                estilosTextoToolTip="text-13px"
              />
            </span>
          )
        },
      },
      {
        Header: () => 'Sede',
        accessor: 'nombreSede',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      },
      {
        Header: () => 'Estado',
        accessor: 'urlArchivo',
        className:
          'w-2/12 flex justify-center items-center content-center h-20  my-0 mx-0.5 rounded-md text-14px uppercase ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: ({row}) =>
          !!row.original.urlArchivo && !!row.original.id ? (
            <a
              href={splitArchivo(row.original.urlArchivo)}
              target="_blank"
              className="text-cendiatra-verde-1 underline"
              rel="noreferrer"
              style={{color: '#008c93'}}
            >
              VER CIERRE DE CAJA
            </a>
          ) : row.original.fechaSolicitud ? (
            <p className="text-cendiatra-gris-1 text-13px">ERROR AL GENERAR</p>
          ) : (
            <p className="text-cendiatra-gris-1 text-13px">NO GENERADO</p>
          ),
      },
    ],
    [nombreRecepcionista]
  )
  return (
    <div className="w-full">
      <TablaDinamica
        columns={columnas}
        data={datosCierreCaja}
        actualizarSeleccionado={actualizarSeleccionado}
        estilosTr={
          'w-full flex border-b-2 border-b-gris justify-evenly items-center text-center text-17px text-cendiatra-gris-1 border py-2 border-cendiatra-verde-4 my-2 rounded-lg'
        }
      />
    </div>
  )
}
