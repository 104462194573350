import {ObtenerServicios} from '../../microservicios/Servicio'
import {dbComercial} from '../bases-datos/dbComercial'
import Dexie from 'dexie'

export const AgregarServiciosACache = async () => {
  await dbComercial.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbComercial.table('FechasActualizacion').get({Tabla: 'Servicios'})
    )?.FechaUltimaActualizacion
    let cups = await dbComercial.table('Cups').toArray()
    await ObtenerServicios(fechaUltimaActualizacion).then((res) => {
      dbComercial.Servicios.bulkPut(
        res.data.map((servicio) => ({
          Id: servicio.id,
          CodigoInterno: servicio.codigoInterno,
          Nombre: servicio.nombre,
          CupsId: servicio.cupsId,
          Iva: servicio.iva,
          ClasificacionId: servicio.clasificacionId,
          CodigoCompleto:
            cups.find((x) => x.Id == servicio.cupsId)?.Codigo +
            '-' +
            servicio.codigoInterno,
          SiglaSticker: servicio.siglaSticker,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar servicios al caché comercial: ' + (e.stack || e)
        )
      })
      dbComercial.FechasActualizacion.put({
        Tabla: 'Servicios',
        FechaUltimaActualizacion: res.headers.tiemposervidor,
      })
    })
  })
}

export const ObtenerTodosLosServicios = async () => {
  return Promise.resolve(dbComercial.table('Servicios').toArray())
}

export const ObtenerServiciosPorNombre = async (filtro) => {
  let serviciosPorNombre = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
    codigo: elemento.CodigoCompleto,
    nombre: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorNombre)
}

export const ObtenerServicioPorNombre = async (nombre) => {
  return Promise.resolve(dbComercial.table('Servicios').get({Nombre: nombre}))
}

export const ObtenerServicioPorId = async (id) => {
  return Promise.resolve(dbComercial.table('Servicios').get({Id: id}))
}

export const ObtenerServiciosPorCodigo = async (filtro) => {
  let serviciosPorCodigo = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({CodigoCompleto}) =>
          CodigoCompleto.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.CodigoCompleto,
    codigo: elemento.CodigoCompleto,
    nombre: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorCodigo)
}

export const ObtenerUnicoServicioPorNombre = async (filtro) => {
  let serviciosPorNombre = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre}) =>
          Nombre.trim().toUpperCase() === filtro.trim().toUpperCase()
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorNombre)
}

export const ObtenerUnicaSiglaStickerPorNombre = async (filtro) => {
  let serviciosPorNombre = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({SiglaSticker}) =>
          SiglaSticker.trim().toUpperCase() === filtro.trim().toUpperCase()
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorNombre)
}

export const obtenerServiciosPorIds = (ids) => {
  if (Array.isArray(ids)) {
    return dbComercial
      .table('Servicios')
      .filter((servicio) => ids.includes(servicio.Id))
      .toArray()
  }

  return Promise.resolve([])
}

export const ObtenerServiciosPorNombreOCodigo = async (filtro) => {
  let servicios = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre, CodigoCompleto}) =>
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
          CodigoCompleto.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
    codigo: elemento.CodigoCompleto,
  }))
  return Promise.resolve(servicios)
}
