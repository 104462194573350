import React, {useState} from 'react'

export const CheckboxRegenerarCaja = ({
  row: {original},
  actualizarSeleccionado,
}) => {
  const [estaActivo, setEstaActivo] = useState(false)

  const onChange = (event) => {
    const isChecked = event.target.checked
    setEstaActivo(isChecked)

    if (original) {
      actualizarSeleccionado([{...original, estaActivo: isChecked}])
    }
  }

  return (
    <div>
      {original && !original.urlArchivo && !original.fechaSolicitud ? (
        <div className="w-full flex justify-center items-center -mt-3">
          <input
            type="checkbox"
            className="checkboxEspecial personalizado"
            onChange={onChange}
            checked={estaActivo}
          />
        </div>
      ) : null}
    </div>
  )
}

export default CheckboxRegenerarCaja
