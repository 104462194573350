import {ObtenerCups} from '../../microservicios'
import {dbComercial} from '../bases-datos/dbComercial'
import Dexie from 'dexie'
import moment from 'moment'

export const AgregarCupsSACache = async (sinfecha = false) => {
  await dbComercial.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbComercial.table('FechasActualizacion').get({Tabla: 'Cups'})
    )?.FechaUltimaActualizacion
    await ObtenerCups(sinfecha ? undefined : fechaUltimaActualizacion).then(
      (res) => {
        if (Array.isArray(res.data)) {
          dbComercial.Cups.bulkPut(
            res.data.map((cup) => ({
              Id: cup.id,
              Codigo: cup.codigo,
              Nombre: cup.nombre,
            }))
          ).catch(Dexie.BulkError, function (e) {
            console.error(
              'Error al agregar empresas al caché comercial: ' + (e.stack || e)
            )
          })
          dbComercial.FechasActualizacion.put({
            Tabla: 'Cups',
            FechaUltimaActualizacion: moment().format('YYYY-MM-DDTHH:mm:ss'),
          })
        }
      }
    )
  })
}
