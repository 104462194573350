import {dbComun} from '../bases-datos/dbComun'
import {dbComercial} from '../bases-datos'

export const ObtenerCupsPorCodigoONombre = async (filtro) => {
  let cupsPorNombre = (
    await dbComercial
      .table('Cups')
      .filter(
        ({Nombre, Codigo}) =>
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
          Codigo.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Codigo + ' - ' + elemento.Nombre,
    codigo: elemento.Codigo,
  }))
  return Promise.resolve(cupsPorNombre)
}

export const ValidarCupsPorNombreOCodigoExacto = async (filtro) => {
  const filtroNormalizado = filtro.trim().toUpperCase()
  const cupsPorNombre = (
    await dbComercial
      .table('Cups')
      .filter(
        ({Nombre, Codigo}) =>
          Nombre.trim().toUpperCase() === filtroNormalizado ||
          Codigo.trim().toUpperCase() === filtroNormalizado
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Codigo + ' - ' + elemento.Nombre,
    codigo: elemento.Codigo,
  }))
  return Promise.resolve(cupsPorNombre)
}

export const ObtenerCupPorId = async (id) => {
  return Promise.resolve(dbComercial.table('Cups').get({Id: id}))
}
