export const regexTextoCaracteresBasicos =
  /^$|^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,\[/\]/)(+-//\s]+$/
export const regexFormula =
  /^$|^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,\[/\]/)(+-//\s@{}^*]+$/
export const regexTextoCaracteresBasicosConOtrosCaracteresMas =
  /^$|^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,\[/\]/)(+-:<>%//\s]+$/
export const regexTextoConPreguntaYOtrosCaracteresMas =
  /^$|^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,\[/\]/)(+-//\s/¿\?/\:\<\>\%]+$/
export const regexCorreoElectronico =
  /^[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z0-9]{2,}$/
export const regexSoloLetrasYNumeros = /^[a-zA-Z0-9]+$/
export const regexSoloNumeros = /^[\d-]+$/
export const regexNombreUsuario = /^[a-zA-Z0-9_.-]+$/
export const regexCamposTextoConCaracteresEspecificos =
  /^[a-zA-Z0-9\s+\(\)\[\],\/\-\.&%><:]*$/
export const regexFiltroPaquetes = /[.0-9\s--]+/
export const regexNombreEmpresaHC =
  /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/\.,&\[\]\(\)+\-\/\s._,]+$/
export const regexDireccion = /^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,#\[/\]/)(+-//\s]+$/
export const regexValorContrato = /^[0-9\s,'.]+$/
export const regexPorcentaje = /^[0-9%]+$/
export const regexSinCaracteresEspeciales = /^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ\s]+$/
export const regexSoloLetras = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/
export const regexBasicoV1 =
  /^(?:[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\/.,\[\]()+\-\/\s._,&]|\/\/)+$/
export const regexValidarNit = /^[0-9()\-.,\[\]/]+$/
export const caracteresNoPermitidosB2c = /[/&%$#"=)(¿¡?+*~{}`´^~;,'@ ]/
export const caracteresNoPermitidosMail = /[/&%$#"=)(¿¡?+*~{}`´^~;,']/

export const voucherRegex = /^[a-zA-Z0-9\-/]+$/
export const regexBasicoV2 =
  /^(?:[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\/.,\[\]()*+\-\/\s._,&]|\/\/)+$/

export const regexCodigoCUPS = /^[A-Za-z0-9]\d\.\d\.\d\.\d{2}$/

export const regexBasicoV3 =
  /^(?:[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\[\]\(\)\+\/\-_.\?\s])+$/

export const regexBasicoV4 =
  /^(?:[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\[\]\(\)\+\/\-_.\?,\s])+$/
