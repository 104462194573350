import {Input} from '../inputs'
import {Controller, useForm} from 'react-hook-form'
import clsx from 'clsx'
import {Boton} from '../Botones'
import {useContext, useEffect, useMemo, useState} from 'react'
import {ContextApplication} from '../../contexto'
import {GuardarControlTiquetera} from '../../microservicios/ControlVentaTiqueteras'
import {ObtenerUsuariosEjecutivoComercial} from '../../microservicios'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'

const FormularioAsignacionEntrega = ({
  informacion,
  refrescarDatos,
  precargarDatos = false,
}) => {
  const contextoAplicacion = useContext(ContextApplication)
  const [usuariosComerciales, setUsuariosComerciales] = useState([])
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      numeroLibreta: '',
      consecutivoInicial: '',
      consecutivoFinal: '',
      responsableEntrega: '',
      filtros: {
        ejecutivoComercial: '',
      },
    },
  })

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  useEffect(() => {
    if (!precargarDatos) return
    form.reset({
      numeroLibreta: informacion.numeroLibreta,
      consecutivoInicial: informacion.consecutivoInicial,
      consecutivoFinal: informacion.consecutivoFinal,
      responsableEntrega: informacion.responsableEntrega,
      filtros: {ejecutivoComercial: informacion.responsableEntrega},
    })
  }, [precargarDatos, informacion])

  const guardarCambios = async () => {
    try {
      const {filtros, ...resto} = form.getValues()
      const respuesta = await GuardarControlTiquetera({
        ...resto,
        detallePagoId: informacion.detallePagoId,
      })

      if (respuesta.status !== 200) return

      contextoAplicacion.setModal2({
        abierto: true,
        titulo: 'Los datos han sido registrados',
        contenido: '',
        botones: [
          {
            nombre: 'Volver',
            click: () => {
              cerrarModal('setModal')
              cerrarModal('setModal2')
              refrescarDatos()
            },
          },
        ],
        clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
      })
    } catch (error) {
      console.log(error)
    }
  }

  const avisoGuardarCambios = async () => {
    const valido = await form.trigger()

    if (!valido) return

    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '¿Está de acuerdo con los datos registrados?',
      contenido: (
        <div className="w-full">
          {' '}
          <h6 className="normal-case text-cendiatra font-bold text-sm flex flex-col w-full justify-items-start justify-start text-center pb-6">
            Esta acción no se puede deshacer
          </h6>
          <div className="w-full flex items-center justify-center">
            <Boton
              titulo="Regresar"
              lineaPrimerCaracter={true}
              habilitado={true}
              funcionCLick={() => cerrarModal('setModal2')}
              colorPorDefecto={'bg-transparent'}
              colorActivo={
                'opacity-70 text-cendiatra border border-transparent font-bold rounded-md'
              }
              tipo={'button'}
            />
            <Boton
              titulo="Continuar"
              lineaPrimerCaracter={true}
              habilitado={true}
              funcionCLick={guardarCambios}
              colorPorDefecto={'bg-grayColor'}
              colorActivo={'bg-btnBg'}
              tipo={'button'}
            />
          </div>
        </div>
      ),
      botones: [],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const filtrarUsuariosEjecutivos = useMemo(
    () =>
      usuariosComerciales
        .filter((u) =>
          u.correo.includes(form.watch('filtros.ejecutivoComercial'))
        )
        .map((u) => {
          const userName = u?.correo?.split('@')?.[0] ?? ''
          return {id: userName, filtro: userName}
        }),
    [form.watch('filtros.ejecutivoComercial')]
  )

  useEffect(() => {
    form.trigger()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const respuesta = await ObtenerUsuariosEjecutivoComercial()
        setUsuariosComerciales(respuesta?.data ?? [])
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <div className="w-full flex justify-center items-center flex-wrap -mt-10">
      <h2 className="normal-case font-bold text-xl flex flex-col w-full justify-items-center justify-center text-center pb-10">
        Asignar datos para entrega
      </h2>
      <div className="w-full grid grid-cols-6 text-sm text-left gap-4 pb-10">
        <div className="col-span-1 flex flex-col justify-start align-items-start">
          <p>FECHA</p>
          <p className="text-cendiatra-gris-1">{informacion.fechaPago}</p>
        </div>
        <div className="col-span-3">
          <p>TIPO DE TIQUETERA</p>
          <p className="text-cendiatra-gris-1">{informacion.nombreTiquetera}</p>
        </div>
        <div className="col-span-2">
          <p>Nº FACTURA</p>
          <p className="text-cendiatra-gris-1">{informacion.numeroFactura}</p>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 pb-2">
        <div
          className={`flex justify-center items-end w-full text-left col-span-3`}
        >
          <Controller
            name="numeroLibreta"
            control={form.control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}, fieldState}) => (
              <Input
                erroresFormulario={fieldState.error?.message}
                mostrarErrores={!!fieldState.error}
                estilosContenedor={'w-full mb-2'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full p-1.5 border text-cendiatra-gris-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 text-sm',
                  {
                    'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1':
                      !!fieldState.error,
                    'focus:border-cendiatra border-cendiatra':
                      !fieldState.error,
                  }
                )}
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nº Libreta'}
                onChange={(evt) =>
                  onChange(evt.target.value.replace(/\D/g, ''))
                }
                valor={value}
              />
            )}
          />
        </div>
        <div className="flex flex-col col-span-6">
          <h6 className="normal-case flex flex-col w-full justify-items-center justify-center text-center pb-1.5">
            CONSECUTIVO
          </h6>
          <div className="grid grid-cols-12 gap-1">
            <div
              className={`flex justify-center items-end w-full text-left col-span-5`}
            >
              <Controller
                name="consecutivoInicial"
                control={form.control}
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}, fieldState}) => (
                  <Input
                    erroresFormulario={fieldState.error?.message}
                    mostrarErrores={!!fieldState.error}
                    estilosContenedor={'w-full mb-2'}
                    estilosInput={clsx(
                      'appearance-none rounded relative block w-full p-1.5 border text-cendiatra-gris-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 text-sm',
                      {
                        'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1':
                          !!fieldState.error,
                        'focus:border-cendiatra border-cendiatra':
                          !fieldState.error,
                      }
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={''}
                    onChange={onChange}
                    valor={value}
                  />
                )}
              />
            </div>
            <p className="col-span-2 mt-2">-</p>
            <div
              className={`flex justify-center items-end w-full text-left col-span-5`}
            >
              <Controller
                name="consecutivoFinal"
                control={form.control}
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}, fieldState}) => (
                  <Input
                    erroresFormulario={fieldState.error?.message}
                    mostrarErrores={!!fieldState.error}
                    estilosContenedor={'w-full mb-2'}
                    estilosInput={clsx(
                      'appearance-none rounded relative block w-full p-1.5 border text-cendiatra-gris-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 text-sm',
                      {
                        'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1':
                          !!fieldState.error,
                        'focus:border-cendiatra border-cendiatra':
                          !fieldState.error,
                      }
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={''}
                    onChange={onChange}
                    valor={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex justify-start items-start w-full text-left col-span-3`}
        >
          <Controller
            name="responsableEntrega"
            control={form.control}
            rules={{
              required: true,
            }}
            render={({field: {onChange}, fieldState}) => (
              <CampoFiltrarEspañol
                titulo={'Comercial'}
                contenedorPrincipal={'w-full'}
                estilosAdicionalesContenedor={'my-0px'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  !!fieldState.error
                    ? 'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1'
                    : 'border-cendiatra text-cendiatra-gris-3 focus:ring-indigo-500 focus:border-cendiatra'
                )}
                tipo={'text'}
                placeholder={'Autocompletar'}
                valorDelCampoFiltro={form.watch('filtros.ejecutivoComercial')}
                informacionFiltrada={filtrarUsuariosEjecutivos}
                desactivarOtroFiltro={false}
                handleChange={(evt) =>
                  form.setValue('filtros.ejecutivoComercial', evt.target.value)
                }
                handleOptionChange={(evt) => {
                  onChange(evt.filtro)
                  form.setValue('filtros.ejecutivoComercial', evt?.filtro ?? '')
                }}
              />
            )}
          />
        </div>
      </div>
      <h6 className="normal-case text-cendiatra-rojo-1 font-bold text-sm flex flex-col w-full justify-items-start justify-start text-center pb-6">
        Esta acción no se puede deshacer
      </h6>
      <div className="w-full flex items-center justify-center">
        <Boton
          titulo="Regresar"
          lineaPrimerCaracter={true}
          habilitado={true}
          funcionCLick={() => cerrarModal('setModal')}
          colorPorDefecto={'bg-transparent'}
          colorActivo={
            'opacity-70 text-cendiatra border border-transparent font-bold rounded-md'
          }
          tipo={'button'}
        />
        <Boton
          titulo="Guardar"
          lineaPrimerCaracter={true}
          habilitado={form.formState.isValid}
          funcionCLick={avisoGuardarCambios}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
        />
      </div>
    </div>
  )
}

export default FormularioAsignacionEntrega
