import {getRestApi} from './ApiAxios'

export const ObtenerObservacionMedicaPorAtencionId = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'gato-api-function/ObtenerObservacionMedicaPorAtencionId',
    params: {atencionId},
  })
}
