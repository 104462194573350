import imprimirVerde from '../../imagenes/iconoImprimirVerde.svg'
import {ObtenerDocumentoPDF} from '../../microservicios'

const InfoFacturaImpresion = ({numeroFactura, facturaId}) => {
  const visualizarDocumentoPDF = async () => {
    try {
      const respuesta = await ObtenerDocumentoPDF(facturaId ?? 0)
      if (respuesta.status === 200) window.open(respuesta.data, '_blank')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex items-center">
      <span className="w-9/12">{numeroFactura}</span>
      <div className="w-3/12">
        <img
          src={imprimirVerde}
          alt="imprimir"
          className="cursor-pointer"
          onClick={visualizarDocumentoPDF}
        />
      </div>
    </div>
  )
}

export default InfoFacturaImpresion
