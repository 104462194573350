import React from 'react'
import {useMsal} from '@azure/msal-react'
import MenuDinamico from './MenuDinamico.jsx'
import serviciosImg from '../../imagenes/servicios.png'
import ojoIcono from '../../imagenes/ojoIcono.png'
import iconoFacturasPendientesCorreccionSede from '../../imagenes/iconoFacturasPendientesCorreccionSede.svg'
import iconoGestionFacturacion from '../../imagenes/iconoGestionFacturacion.svg'
import paqueteImg from '../../imagenes/paquetes.png'
import tarifarioImg from '../../imagenes/tarifario.png'
import empresaImg from '../../imagenes/empresa.png'
import empresaHijaImg from '../../imagenes/empresaHija.png'
import agendaRegistro from '../../imagenes/menuRecepcion/agendaRegistro.svg'
import asignarTurno from '../../imagenes/menuRecepcion/asignarTurno.svg'
import cierreDeCaja from '../../imagenes/menuRecepcion/cierreDeCaja.svg'
import llamarPacientes from '../../imagenes/menuRecepcion/llamarPacientes.svg'
import modificarPaciente from '../../imagenes/menuRecepcion/modificarPaciente.svg'
import verModificarPaciente from '../../imagenes/menuRecepcion/verModificarPaciente.svg'
import registroPaciente from '../../imagenes/menuRecepcion/registroPaciente.svg'
import gestionRol from '../../imagenes/menuAdministrador/GestionRol.svg'
import gestionFirma from '../../imagenes/menuAdministrador/GestionFirma.svg'
import crearPlantilla from '../../imagenes/menuAdministrador/crearPlantilla.svg'
import verReportes from '../../imagenes/menuComercial/VerReportes.svg'
import solicitarModificarAtencion from '../../imagenes/menuRecepcion/SolicitarModificarAtencion.svg'
import solicitarAtencion from '../../imagenes/menuRecepcion/SolicitarAtencion.svg'
import entregaResultados from '../../imagenes/menuRecepcion/EntregaResultados.svg'
import modificarAtencionEjecutada from '../../imagenes/menuMedico/ModificarAtencionEjecutada.svg'
import verModificarAtencionEjecutadaIconoVerde from '../../imagenes/VerModificarAtencionIconoVerde.svg'
import reporteAtencion from '../../imagenes/menuMedico/ReporteAtencion.svg'
import cargarResultadoAliado from '../../imagenes/menuAliadoAdministrador/cargarResultadoAliado.svg'
import verModificarResultadoAliado from '../../imagenes/menuAliadoAdministrador/verModificarResultadoAliado.svg'
import openFolderImg from '../../imagenes/openFolder.svg'
import gestionAprobacion from '../../imagenes/menuAdministradorContable/GestionAprobacion.svg'
import gestionComprobante from '../../imagenes/menuAdministradorContable/GestionComprobante.svg'
import gestionFactura from '../../imagenes/menuAdministradorContable/GestionFactura.svg'
import gestionResoluciones from '../../imagenes/menuAdministradorContable/GestionResoluciones.svg'
import registrarResultados from '../../imagenes/menuBacteriologo/RegistrarResultados.svg'
import asignarPlantillaServicio from '../../imagenes/asignarPlantillaServicio.svg'
import iconoControlVersionPlantillas from '../../imagenes/menuAdministrador/IconoControlVersion.svg'
import iconoCrearEditarFuenteDatos from '../../imagenes/menuAdministrador/CrearEditarFuentedeDatos.svg'
import reporteDeVentas from '../../imagenes/menuGerenteGeneral/reporteDeVentas.png'
import gestionUsuarios from '../../imagenes/menuEjecutivoComercial/gestionUsuario.svg'
import iconoOjoVer from '../../imagenes/iconoOjoVer.svg'
import verSaldoMovimientosDetalle from '../../imagenes/Ver_Saldo_Movimientos_y_Detalle.svg'
import recargaEnSede from '../../imagenes/menuRecepcion/recargaEnSede.svg'
import consultaTarifarioParticular from '../../imagenes/menuRecepcion/consultaTarifariosParticular.svg'
import generadorDeReportes from '../../imagenes/menuAdministrador/GeneradorDeReportes.svg'
import contabilidadSvg from '../../imagenes/Contabilidad.svg'
import InformeCondicionesSalud from '../../imagenes/menuSst/informeSalud.svg'
import regenerarCierreCaja from '../../imagenes/menuGerenteGeneral/Ver-regenerar_cierre_de_caja.svg'
import {RUTAS_APP, TEXTO_VISUAL, ROLES_APP} from '../../constantes'
import {validarRolAsignado} from '../../utilidades'
import {useFeatureIsOn} from '@growthbook/growthbook-react'
import {TEXTO_FEATURE_FLAGS} from '../../constantes/featureFlags.js'
import iconoOtrosServicios from '../../imagenes/menuComercial/Icono_GestiondepreciosparaOtrosServicios.svg'
import iconoTesorero from '../../imagenes/tesorero/CargadeBancos.svg'
import logoConfirmacionDePagos from '../../imagenes/ConfirmaciondePagos.svg'
import logoFacturacionConfirmacion from '../../imagenes/Facturacion.svg'
import IconoUnificarPaciente from '../../imagenes/IconoUnificarPaciente.svg'
import GestionVentaTiqueteras from '../../imagenes/GestionVentaTiqueteras.svg'
import ControlVentaTiqueteras from '../../imagenes/ControlVentasTiqueteras.svg'
import GestionCups from '../../imagenes/IconoCUPS.svg'

const BarraNavegacion = () => {
  const {accounts} = useMsal()

  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const habilitarNuevaVersionReportes = useFeatureIsOn(
    TEXTO_FEATURE_FLAGS.NUEVO_UI_REPORTES
  )

  const arrayMenus = [
    // MENU RECEPCIONISTA
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.ASIGNAR_TURNO,
      imagen: asignarTurno,
      ruta: RUTAS_APP.RECEPCION.ASIGNAR_TURNO,
      rol: [ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO],
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.LLAMAR_PACIENTES,
      imagen: llamarPacientes,
      ruta: RUTAS_APP.RECEPCION.LLAMAR_PACIENTE,
      rol: [ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.REGISTRO_DE_PACIENTES,
      imagen: registroPaciente,
      ruta: RUTAS_APP.RECEPCION.REGISTRAR_PACIENTE,
      rol: [ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO],
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.AGENDA_REGISTRO,
      imagen: agendaRegistro,
      ruta: RUTAS_APP.RECEPCION.AGENDA_REGISTRO,
      rol: [
        ROLES_APP.RECEPCION,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.RECEPCION_ALIADO,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA
          .VER_MODIFICAR_PACIENTES,
      imagen: verModificarPaciente,
      ruta: RUTAS_APP.RECEPCION.MODIFICAR_PACIENTES,
      rol: [
        ROLES_APP.RECEPCION,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.RECEPCION_ALIADO,
        ROLES_APP.ADMINISTRADOR_ALIADO,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA
          .MODIFICAR_ATENCION_EJECUTADA,
      imagen: solicitarAtencion,
      ruta: RUTAS_APP.RECEPCION.MODIFICAR_ATENCION_EJECUTADA,
      rol: [ROLES_APP.JEFE_SEDE, ROLES_APP.ADMINISTRADOR_ALIADO],
    },
    // {
    //   titulo: 'Entrega de Resultados',
    //   imagen: entregaResultados,
    //   ruta: '',
    //   deshabilitado: true,
    //   rol: ROLES_APP.RECEPCION,
    // }, SE DESCOMENTARA ESTE CODIGO CUANDO SE HABILITE ESTA OPCION
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.RECARGAR_EN_SEDE,
      imagen: recargaEnSede,
      ruta: RUTAS_APP.RECEPCION.RECARGAR_SALDO_EN_SEDE,
      rol: ROLES_APP.RECEPCION,
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.GESTION_DE_FACTURACION,
      imagen: iconoGestionFacturacion,
      ruta: RUTAS_APP.GESTION_FACTURACION.RAIZ,
      rol: [ROLES_APP.RECEPCION, ROLES_APP.JEFE_SEDE],
      subMenus: [
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.GESTION_FACTURACION
              .FACTURAS_PENDIENTES_CORRECCION_SEDE,
          imagen: iconoFacturasPendientesCorreccionSede,
          ruta: RUTAS_APP.GESTION_FACTURACION
            .FACTURAS_PENDIENTES_CORRECCION_SEDE,
        },
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.GESTION_FACTURACION
              .CONSULTA_ESTADO_FACTURACION,
          imagen: ojoIcono,
          ruta: RUTAS_APP.GESTION_FACTURACION.CONSULTA_ESTADO_FACTURACION,
        },
      ],
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.CIERRE_DE_CAJA,
      imagen: cierreDeCaja,
      ruta: RUTAS_APP.RECEPCION.CIERRE_DE_CAJA,
      rol: ROLES_APP.RECEPCION,
    },

    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA
          .CONSULTA_TARIFARIO_PARTICULAR,
      imagen: consultaTarifarioParticular,
      ruta: RUTAS_APP.RECEPCION.CONSULTA_TARIFARIO_PARTICULAR,
      rol: ROLES_APP.RECEPCION,
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.GENERADOR_DE_INFORMES,
      imagen: generadorDeReportes,
      ruta: RUTAS_APP.RECEPCION.GENERADOR_DE_INFORMES,
      rol: [
        ROLES_APP.RECEPCION,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.AUDITOR,
        ROLES_APP.ADMINISTRADOR_ALIADO,
      ],
    },
    // MENU ADMINISTRADOR
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRATOR_GATO.GESTION_DE_ROL,
      imagen: gestionRol,
      ruta: RUTAS_APP.ADMINISTRADOR_GATO.GESTION_ROLES,
      rol: ROLES_APP.ADMINISTRADOR,
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRATOR_GATO.GESTION_FIRMA,
      imagen: gestionFirma,
      ruta: RUTAS_APP.ADMINISTRADOR_GATO.GESTION_FIRMA,
      rol: ROLES_APP.ADMINISTRADOR,
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS.TITULO,
      imagen: crearPlantilla,
      ruta: '',
      rol: ROLES_APP.ADMINISTRADOR,
      subMenus: [
        {
          titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS.CREAR,
          imagen: crearPlantilla,
          ruta: RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS.CREAR_PLANTILLA,
        },
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS.VER_MODIFICAR,
          imagen: ojoIcono,
          ruta: RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .ASIGNAR_Y_VER_MODIFICAR_PLANTILLA,
        },
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS
              .ASIGNAR_PLANTILLA_SERVICIO,
          imagen: asignarPlantillaServicio,
          ruta: RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .ASIGNAR_Y_VER_MODIFICAR_PLANTILLA,
        },
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS
              .CONTROL_DE_VERSIONES,
          imagen: iconoControlVersionPlantillas,
          ruta: RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .CONTROL_VERSION_DE_PLANTILLAS,
        },
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS
              .CREAR_EDITAR_FUENTE_DATOS,
          imagen: iconoCrearEditarFuenteDatos,
          ruta: RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .CREAR_EDITAR_FUENTE_DATOS,
        },
      ],
    },
    //MEDICOS Y SUS ESPECIALIDADES
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MEDICO_OCUPACIONAL.AGENDA_MEDICO,
      imagen: asignarTurno,
      ruta: RUTAS_APP.MEDICO.AGENDA_MEDICO,
      rol: [
        ROLES_APP.MEDICO_OCUPACIONAL,
        ROLES_APP.AUXILIAR_ENFERMERIA,
        ROLES_APP.BACTERIOLOGO,
        ROLES_APP.FONOAUDIOLOGO,
        ROLES_APP.OPTOMETRA,
        ROLES_APP.PSICOLOGO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.FISIOTERAPEUTA,
      ],
    },
    //MENU BACTERIOLOGO
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_BACTERIOLOGO.REGISTRAR_RESULTADOS,
      imagen: registrarResultados,
      ruta: RUTAS_APP.BACTERIOLOGIA.RESULTADOS_EXAMEN,
      rol: ROLES_APP.BACTERIOLOGO,
    },
    //
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MEDICO_OCUPACIONAL
          .VER_MODIFICAR_EXAMENES_ATENDIDOS.TITULO,
      imagen: verModificarAtencionEjecutadaIconoVerde,
      ruta: RUTAS_APP.MEDICO.VER_EXAMENES_ATENDIDOS_PARA_SOLICITUDES,
      deshabilitado: false,
      rol: [
        ROLES_APP.MEDICO_OCUPACIONAL,
        ROLES_APP.AUXILIAR_ENFERMERIA,
        ROLES_APP.FONOAUDIOLOGO,
        ROLES_APP.OPTOMETRA,
        ROLES_APP.PSICOLOGO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.BACTERIOLOGO,
        ROLES_APP.FISIOTERAPEUTA,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MEDICO_OCUPACIONAL
          .REPORTE_ATENCION_DIARIA,
      imagen: reporteAtencion,
      ruta: RUTAS_APP.COMPARTIDO.REPORTE_ATENCION_DIARIA,
      // deshabilitado: true,
      rol: [
        ROLES_APP.MEDICO_OCUPACIONAL,
        ROLES_APP.AUXILIAR_ENFERMERIA,
        ROLES_APP.FONOAUDIOLOGO,
        ROLES_APP.OPTOMETRA,
        ROLES_APP.PSICOLOGO,
        ROLES_APP.FISIOTERAPEUTA,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.BACTERIOLOGO,
      ],
    },
    // MENU TESORERO
    {
      titulo: 'Carga de bancos',
      imagen: iconoTesorero,
      ruta: RUTAS_APP.TESORERO.CARGA_ARCHIVOS_BANCOS,
      rol: [
        ROLES_APP.TESORERO,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.AUXILIAR_TESORERO,
      ],
    },
    // MENU ADMINISTRADOR ALIADO
    {
      titulo: 'Cargar Resultado Aliado',
      imagen: cargarResultadoAliado,
      ruta: '/cargarresultadosaliado',
      rol: ROLES_APP.ADMINISTRADOR_ALIADO,
    },
    {
      titulo: 'Ver/Modificar Resultado Aliado',
      imagen: verModificarResultadoAliado,
      ruta: '/editarresultadosaliado',
      rol: ROLES_APP.ADMINISTRADOR_ALIADO,
    },
    // MENU ADMINISTRADOR CONTABLE
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRADOR_CONTABLE
          .GESTION_RESOLUCIONES,
      imagen: gestionResoluciones,
      ruta: RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.RAIZ,
      rol: ROLES_APP.ADMINISTRADOR_CONTABLE,
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRADOR_CONTABLE
          .GESTION_FACTURA,
      imagen: gestionFactura,
      ruta: RUTAS_APP.GESTION_FACTURA_ELECTRONICA,
      rol: [
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.FACTURADOR,
        ROLES_APP.AUDITOR,
        ROLES_APP.ADMINISTRADOR,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRADOR_CONTABLE
          .CREAR_NOTA_CREDITO,
      imagen: gestionAprobacion,
      ruta: RUTAS_APP.ADMINISTRADOR_CONTABLE.CREAR_NOTA_CREDITO,
      rol: ROLES_APP.ADMINISTRADOR_CONTABLE,
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRADOR_CONTABLE
          .GESTION_COMPROBANTE,
      imagen: gestionComprobante,
      ruta: '',
      rol: ROLES_APP.ADMINISTRADOR_CONTABLE,
    },

    // MENU JEFE DE SEDE
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE
          .HABILITAR_MODIFICACION_DE_EXAMENES,
      imagen: solicitarAtencion,
      ruta: RUTAS_APP.JEFE_DE_SEDE.HABILITAR_MODIFICAR_EXAMENES_ATENDIDOS,
      rol: [
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.ADMINISTRADOR,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE
          .REPORTE_EJECUCION_DIARIA_POR_SEDE,
      imagen: iconoGestionFacturacion,
      ruta: RUTAS_APP.JEFE_DE_SEDE.REPORTE_EJECUCION_DIARIA_POR_SEDE,
      rol: [ROLES_APP.JEFE_SEDE, ROLES_APP.AUDITOR],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE.RESUMEN_POR_ENTIDAD,
      imagen: iconoGestionFacturacion,
      ruta: RUTAS_APP.JEFE_DE_SEDE.RESUMEN_POR_ENTIDAD,
      rol: [ROLES_APP.JEFE_SEDE, ROLES_APP.AUDITOR],
    },
    // MENU GERENTE GENERAL
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_GERENTE_GENERAL.REPORTE_DE_VENTAS,
      imagen: reporteDeVentas,
      ruta: RUTAS_APP.GERENTE_GENERAL.REPORTE_DE_VENTAS,
      rol: [
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_COMERCIAL,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_GERENTE_GENERAL
          .REGENERAR_CIERRE_CAJA,
      imagen: regenerarCierreCaja,
      ruta: RUTAS_APP.GERENTE_GENERAL.VER_REGENERAR_CIERRE_CAJA,
      rol: [
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.AUDITOR,
      ],
    },
    // MENU EJECUTIVO COMERCIAL
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_EJECUTIVO_COMERCIAL
          .GESTION_USUARIOS_PORTAL_CLIENTE,
      imagen: gestionUsuarios,
      ruta: RUTAS_APP.EJECUTIVA_COMERCIAL.GESTION_USUARIOS_PORTAL_CLIENTE,
      rol: [
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.LICITADOR,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_EJECUTIVO_COMERCIAL
          .VER_ATENCION_EMPRESAS,
      imagen: iconoOjoVer,
      ruta: RUTAS_APP.EJECUTIVA_COMERCIAL.VER_ATENCIONES,
      rol: [
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.FACTURADOR,
        ROLES_APP.LICITADOR,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    // MENU COMERCIAL
    {
      titulo: 'Servicios',
      imagen: serviciosImg,
      ruta: '',
      rol: ROLES_APP.COMERCIAL,
      subMenus: [
        {
          titulo: 'Crear',
          imagen: serviciosImg,
          ruta: '/services',
          rol: ROLES_APP.COMERCIAL,
        },
        {
          titulo: 'Ver / Modificar',
          imagen: ojoIcono,
          ruta: '/editarservicio',
          rol: ROLES_APP.COMERCIAL,
        },
      ],
    },

    {
      titulo: 'Paquetes',
      imagen: paqueteImg,
      ruta: '',
      rol: [ROLES_APP.COMERCIAL, ROLES_APP.LICITADOR],
      subMenus: [
        {
          titulo: 'Crear',
          imagen: paqueteImg,
          ruta: RUTAS_APP.COMERCIAL.CREAR_PAQUETES,
        },
        {
          titulo: 'Ver / Modificar',
          imagen: ojoIcono,
          ruta: RUTAS_APP.COMERCIAL.EDITAR_PAQUETE,
        },
      ],
    },
    {
      titulo: 'Empresa',
      imagen: empresaImg,
      ruta: '',
      rol: [ROLES_APP.COMERCIAL, ROLES_APP.LICITADOR, ROLES_APP.ADMINISTRADOR],
      subMenus: [
        {
          titulo: 'Crear',
          imagen: empresaImg,
          ruta: '/crearempresa',
          rol: [
            ROLES_APP.COMERCIAL,
            ROLES_APP.LICITADOR,
            ROLES_APP.ADMINISTRADOR,
          ],
        },
        {
          titulo: 'Ver / Modificar',
          imagen: ojoIcono,
          ruta: '/editarempresa',
          rol: [
            ROLES_APP.COMERCIAL,
            ROLES_APP.LICITADOR,
            ROLES_APP.ADMINISTRADOR,
          ],
        },
        {
          titulo: 'Crear Empresas Hijas',
          imagen: empresaHijaImg,
          ruta: '/crearempresahija',
          rol: [
            ROLES_APP.COMERCIAL,
            ROLES_APP.LICITADOR,
            ROLES_APP.ADMINISTRADOR,
          ],
        },
        {
          titulo: 'Ver / Modificar Empresas Hijas',
          imagen: ojoIcono,
          ruta: '/editarempresahija',
          rol: [
            ROLES_APP.COMERCIAL,
            ROLES_APP.LICITADOR,
            ROLES_APP.ADMINISTRADOR,
          ],
        },
      ],
    },

    {
      titulo: 'Tarifario',
      imagen: tarifarioImg,
      ruta: '',
      rol: [ROLES_APP.COMERCIAL, ROLES_APP.LICITADOR, ROLES_APP.ADMINISTRADOR],
      subMenus: [
        {
          titulo: 'Crear',
          imagen: paqueteImg,
          ruta: '/creartarifario',
          rol: [
            ROLES_APP.COMERCIAL,
            ROLES_APP.LICITADOR,
            ROLES_APP.ADMINISTRADOR,
          ],
        },
        {
          titulo: 'Ver / Modificar',
          imagen: ojoIcono,
          ruta: '/editartarifario',
          rol: [
            ROLES_APP.COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.ADMINISTRADOR,
          ],
        },
      ],
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.PORTAFOLIO.TITULO,
      imagen: openFolderImg,
      ruta: RUTAS_APP.PORTAFOLIO.RAIZ,
      rol: ROLES_APP.COMERCIAL,
      subMenus: [
        {
          titulo: TEXTO_VISUAL.BARRA_NAVEGACION.PORTAFOLIO.CREAR,
          imagen: openFolderImg,
          ruta: RUTAS_APP.PORTAFOLIO.CREAR,
        },
        {
          titulo: TEXTO_VISUAL.BARRA_NAVEGACION.PORTAFOLIO.EDITAR,
          imagen: ojoIcono,
          ruta: RUTAS_APP.PORTAFOLIO.EDITAR,
        },
      ],
    },
    {
      titulo: 'Ver Reportes',
      imagen: verReportes,
      ruta: '',
      deshabilitado: true,
      rol: ROLES_APP.COMERCIAL,
      subMenus: [],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_PROFESIONAL_SST
          .INFORME_CONDICIONES_DE_SALUD,
      imagen: InformeCondicionesSalud,
      ruta: RUTAS_APP.PROFESIONAL_SST.INFORME_CONDICIONES_DE_SALUD,
      rol: [ROLES_APP.PROFESIONAL_SST],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRATOR_GATO
          .GESTION_DE_REPORTES,
      imagen: generadorDeReportes,
      ruta: RUTAS_APP.ADMINISTRADOR_GATO.GESTION_DE_REPORTES,
      rol: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.FACTURADOR,
        ROLES_APP.LICITADOR,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_FINANCIERO,
        ROLES_APP.AUXILIAR_TESORERO,
        ROLES_APP.ADMINISTRADOR_ALIADO,
      ],
    },

    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.AUX_CONTABLE.CONTABILIDAD,
      imagen: contabilidadSvg,
      ruta: RUTAS_APP.AUX_CONTABLE.CONTABILIDAD,
      rol: [
        ROLES_APP.TESORERO,
        ROLES_APP.RECEPCION,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.FACTURADOR,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.GERENTE_FINANCIERO,
        ROLES_APP.AUXILIAR_TESORERO,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.AUDITOR,
      ],
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_GERENTE_FINANCIERO.SERVICIOS,
      imagen: serviciosImg,
      ruta: '',
      rol: ROLES_APP.GERENTE_FINANCIERO,
      subMenus: [
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MENU_GERENTE_FINANCIERO.VER_SERVICIOS,
          imagen: ojoIcono,
          ruta: RUTAS_APP.COMERCIAL.EDITAR_SERVICIO,
          rol: ROLES_APP.GERENTE_FINANCIERO,
        },
      ],
    },
    {
      titulo: TEXTO_VISUAL.BARRA_NAVEGACION.MENU_GERENTE_FINANCIERO.PAQUETES,
      imagen: paqueteImg,
      ruta: '',
      rol: ROLES_APP.GERENTE_FINANCIERO,
      subMenus: [
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MENU_GERENTE_FINANCIERO.VER_PAQUETES,
          imagen: ojoIcono,
          ruta: RUTAS_APP.COMERCIAL.EDITAR_PAQUETE,
          rol: ROLES_APP.GERENTE_FINANCIERO,
        },
      ],
    },
    {
      titulo: 'Empresa',
      imagen: empresaImg,
      ruta: '',
      rol: [
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.FACTURADOR,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_FINANCIERO,
        ROLES_APP.ADMINISTRADOR_ALIADO,
      ],
      subMenus: [
        {
          titulo: 'Ver Empresas',
          imagen: ojoIcono,
          ruta: '/editarempresa',
        },

        {
          titulo: 'Ver Empresas Hijas',
          imagen: ojoIcono,
          ruta: '/editarempresahija',
        },
      ],
    },
    {
      titulo: 'Tarifario',
      imagen: tarifarioImg,
      ruta: '',
      rol: [
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.FACTURADOR,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_FINANCIERO,
        ROLES_APP.ADMINISTRADOR_ALIADO,
      ],
      subMenus: [
        {
          titulo: 'Ver Tarifarios',
          imagen: ojoIcono,
          ruta: '/editartarifario',
        },
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.GERENTE_COMERCIAL.GESTION_DE_PRECIOS_PARA_OTROS_SERVICIOS,
      imagen: iconoOtrosServicios,
      ruta: RUTAS_APP.GERENTE_COMERCIAL.GESTION_OTROS_SERVICIOS,
      rol: [ROLES_APP.GERENTE_COMERCIAL],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_RECEPCIONISTA.VER_SALDO_DEL_CLIENTE,
      imagen: verSaldoMovimientosDetalle,
      ruta: RUTAS_APP.COMPARTIDO.VER_SALDO_DEL_CLIENTE,
      rol: [
        ROLES_APP.RECEPCION,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.COMERCIAL,
        ROLES_APP.AUDITOR,
        ROLES_APP.AUXILIAR_TESORERO,
      ],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRATOR_GATO.GESTION_DE_CUPS,
      imagen: GestionCups,
      ruta: '',
      rol: [ROLES_APP.ADMINISTRADOR, ROLES_APP.COMERCIAL],
      subMenus: [
        {
          titulo:
            TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRATOR_GATO.CREAR_CUPS,
          imagen: serviciosImg,
          ruta: RUTAS_APP.COMPARTIDO.CREAR_CUPS,
        },
      ],
    },
    {
      titulo: TEXTO_VISUAL.CONFIRMACION_PAGOS.TITULO_MENU,
      imagen: logoConfirmacionDePagos,
      ruta: RUTAS_APP.CONFIRMACION_PAGOS.CONFIRMACION_DE_PAGOS,
      rol: [
        ROLES_APP.RECEPCION,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.TESORERO,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.FACTURADOR,
        ROLES_APP.AUXILIAR_TESORERO,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.AUDITOR,
      ],
    },
    {
      titulo: TEXTO_VISUAL.FACTURACION_CONFIRMACION_PAGOS.TITULO_MENU,
      imagen: logoFacturacionConfirmacion,
      ruta: RUTAS_APP.CONFIRMACION_PAGOS.FACTURACION_CONFIRMACION_DE_PAGOS,
      rol: [ROLES_APP.FACTURADOR, ROLES_APP.TESORERO],
    },
    {
      titulo:
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_JEFE_DE_SEDE
          .UNIFICAR_DATOS_DEL_PACIENTE,
      imagen: IconoUnificarPaciente,
      ruta: RUTAS_APP.JEFE_DE_SEDE.UNIFICAR_DATOS_PACIENTE,
      rol: [ROLES_APP.JEFE_SEDE, ROLES_APP.ADMINISTRADOR],
    },
    {
      titulo: TEXTO_VISUAL.CONTROL_VENTA_TIQUETERAS.GESTION_VENTA_DE_TIQUETERAS,
      imagen: GestionVentaTiqueteras,
      ruta: '',
      rol: [
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.TESORERO,
        ROLES_APP.AUXILIAR_TESORERO,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
      ],
      subMenus: [
        {
          titulo:
            TEXTO_VISUAL.CONTROL_VENTA_TIQUETERAS.CONTROL_VENTA_DE_TIQUETERAS,
          imagen: ControlVentaTiqueteras,
          ruta: RUTAS_APP.CONTROL_TIQUETERAS.CONTROL_VENTAS_TIQUETERAS,
          rol: [ROLES_APP.JEFE_SEDE, ROLES_APP.GERENTE_COMERCIAL],
        },
        {
          titulo:
            TEXTO_VISUAL.CONTROL_VENTA_TIQUETERAS.VER_TIQUETERAS_REGISTRADAS,
          imagen: ControlVentaTiqueteras,
          ruta: RUTAS_APP.CONTROL_TIQUETERAS.TIQUETERAS_REGISTRADAS,
          rol: [
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.TESORERO,
            ROLES_APP.AUXILIAR_TESORERO,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
          ],
        },
        {
          titulo:
            TEXTO_VISUAL.CONTROL_VENTA_TIQUETERAS
              .VER_MODIFICAR_TIQUETERAS_REGISTRADAS,
          imagen: ControlVentaTiqueteras,
          ruta: RUTAS_APP.CONTROL_TIQUETERAS.TIQUETERAS_REGISTRADAS,
          rol: [ROLES_APP.ADMINISTRADOR],
        },
      ],
    },
  ]

  const ObtenerDatosMenu = (rol) => {
    const menu = arrayMenus
      .filter(
        (elemento) =>
          elemento?.rol?.includes(rol) && validarRolAsignado(elemento.rol, rol)
      )
      .map((item) => {
        if (item.subMenus && item.subMenus.length) {
          return {
            ...item,
            subMenus: item.subMenus.filter((x) =>
              x?.hasOwnProperty('rol')
                ? x.rol.includes(rol) && validarRolAsignado(x.rol, rol)
                : true
            ),
          }
        }

        return item
      })

    return menu ?? []
  }

  return (
    <>
      <MenuDinamico datos={ObtenerDatosMenu(rolUsuario)} />
    </>
  )
}

export default BarraNavegacion
