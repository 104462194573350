export const PATRON_INPUT_TEXTO =
  /^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,_\[\]/)(+\-\s]+$/

export const PATRON_SOLO_NUMEROS_POSITIVOS = /^\d+$/

export const PATRON_ES_CORREO =
  /^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/

export const PATRON_CORREO_DOMINIO_CON_NUMEROS =
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
