import {useContext, useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'

import {Input} from '../../inputs'
import {
  AgregarEmpresasACache,
  ObtenerTodasLasSedes,
} from '../../../cache/servicios-cache'
import {BotonSimple} from '../../Botones'
import moment from 'moment'
import ListaDesplegableConFiltro from '../../inputs/ListaDesplegableConFiltro'
import {ObtenerUsuariosRecepcion} from '../../../microservicios'
import {useObtenerRol, validarRolAsignado} from '../../../utilidades'
import {ROLES_APP} from '../../../constantes'
import {ContextApplication} from '../../../contexto'

export default function FiltroRegenerarCierre({
  funcionBuscarInformacion,
  funcionLimpiarInformacionTabla,
  funcionRegenerarCierre,
  isChecked,
}) {
  const valorPorDefecto = JSON.parse(
    localStorage.getItem('valorDefectoRegenerar')
  )

  const {rolUsuario} = useObtenerRol()

  const contextoAplicacion = useContext(ContextApplication)

  const {
    control,
    getValues,
    reset,
    trigger,
    setValue,
    setError,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fechaCierreCaja: '',
      usuarioRecepcion: '',
      sede: rolUsuario === ROLES_APP.JEFE_SEDE ? valorPorDefecto : '',
    },
  })

  const selectInputRefSede = useRef()
  const selectInputRefRecepcion = useRef()

  const onClear = () => {
    selectInputRefSede.current.clearValue()
    selectInputRefRecepcion.current.clearValue()
  }

  const [sedes, setSedes] = useState([])

  const [recepcionistas, setRecepcionistas] = useState([])

  const [limpio, setLimpio] = useState(false)

  useEffect(() => {
    let componenteMontado = true
    trigger()
    const obtenerDatosIniciales = async () => {
      await AgregarEmpresasACache()
      const todasLasSedes = await ObtenerTodasLasSedes()

      const sedeTransformada = todasLasSedes.map((sede) => {
        return {value: sede.Id, label: sede.Nombre}
      })

      setSedes(sedeTransformada)
    }
    if (componenteMontado) {
      obtenerDatosIniciales()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  useEffect(() => {
    let componenteMontado = true
    trigger()
    const obtenerUsuariosRecepcion = async () => {
      await AgregarEmpresasACache()
      const usuarioRecepcion = await ObtenerUsuariosRecepcion()

      const usuarioRecepcionTransformado = usuarioRecepcion.data.map(
        (usuario) => {
          return {value: usuario.correo, label: usuario.nombre.toUpperCase()}
        }
      )

      setRecepcionistas(usuarioRecepcionTransformado)
      contextoAplicacion.setUsuariosRecepcion(usuarioRecepcion.data)
    }
    if (componenteMontado) {
      obtenerUsuariosRecepcion()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  const buscarInformacionAlPresionarEnter = (evt) => {
    if (evt.key === 'Enter') {
      if (Object.values(getValues()).some((v) => v.length > 0)) {
        funcionBuscarInformacion(getValues())
      }
    }
  }

  useEffect(() => {
    const inputs = document.querySelectorAll('div div input')

    for (const input of inputs) {
      input.addEventListener('keypress', buscarInformacionAlPresionarEnter)
    }

    return () => {
      for (const input of inputs) {
        input.removeEventListener('keypress', buscarInformacionAlPresionarEnter)
      }
    }
  }, [])

  const limpiarFiltros = () => {
    reset()
    setError('fechaCierreCaja', {type: 'required'})
    setError('sede', {type: 'required'})
    setLimpio(true)
  }

  const fechaMinima = moment().subtract(1, 'month').format('YYYY-MM-DD')
  const fechaMaxima = moment().subtract(1, 'day').format('YYYY-MM-DD')

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <div className="w-8/12 flex flex-wrap space-x-5">
        <Controller
          name="fechaCierreCaja"
          control={control}
          rules={{required: true}}
          render={({field: {value, onChange}}) => {
            return (
              <Input
                titulo={'Fecha cierre de caja*'}
                placeholder={'DD/MM/AAAA'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'fechaCierreCaja'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm w-full '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm w-full '
                }
                estilosContenedor={'w-30%'}
                valor={value}
                onChange={onChange}
                tipo={'date'}
                fechaMinima={fechaMinima}
                fechaMaxima={fechaMaxima}
                onBlur={() => {
                  const fecha = moment(value)
                  const minimaMomt = moment(fechaMinima, 'YYYY-MM-DD')
                  const maximaMomt = moment(fechaMaxima, 'YYYY-MM-DD')
                  if (
                    !fecha.isValid() ||
                    !fecha.isBetween(
                      minimaMomt.startOf('day'),
                      maximaMomt.endOf('day')
                    )
                  )
                    setValue('fechaCierreCaja', '')
                }}
              />
            )
          }}
        />

        <Controller
          name="sede"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegableConFiltro
              onChange={onChange}
              estilosContenedor={'w-30% h-13'}
              estilosLista={
                'appearance-none rounded relative block w-full  p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm w-full '
              }
              titulo={`Sede*`}
              placeholder={'ELEGIR'}
              opciones={sedes}
              valor={
                rolUsuario === ROLES_APP.JEFE_SEDE ? valorPorDefecto : value
              }
              required={true}
              limpio={limpio}
              selectInputRef={selectInputRefSede}
              valorPorDefecto={
                rolUsuario === ROLES_APP.JEFE_SEDE ? valorPorDefecto : value
              }
              deshabilitado={rolUsuario === ROLES_APP.JEFE_SEDE ? true : false}
            />
          )}
        />

        <Controller
          name="usuarioRecepcion"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegableConFiltro
              ref={(ref) => {
                this.selectRef = ref
              }}
              onChange={onChange}
              estilosContenedor={'w-30% h-13'}
              estilosLista={
                'appearance-none rounded relative block w-full  p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm w-full '
              }
              titulo={`Usuario Recepción`}
              placeholder={'ELEGIR'}
              opciones={recepcionistas}
              valor={value}
              selectInputRef={selectInputRefRecepcion}
            />
          )}
        />
      </div>
      <div className="w-4/12 flex flex-wrap gap-3 mt-2">
        <BotonSimple
          texto={'Regenerar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 ${
            isChecked
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }`}
          funcion={() => funcionRegenerarCierre()}
        />
        <BotonSimple
          texto={'Buscar'}
          funcion={async () => {
            const formValid = await trigger()
            if (formValid) funcionBuscarInformacion(getValues())
          }}
          deshabilitado={!isValid}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 ${
            isValid
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }`}
        />
        <BotonSimple
          texto={'Limpiar'}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px mt-6 ml-2 bg-btnBg`}
          funcion={() => {
            funcionLimpiarInformacionTabla()
            limpiarFiltros()
            onClear()
          }}
        />
      </div>
    </div>
  )
}
