import React, {useContext, useEffect} from 'react'
import FormularioCrearServicio from '../../componentes/Servicios/FormularioCrearServicio'
import {CrearNuevoServicio} from '../../microservicios/Servicio'
import {ContextoServicio, ContextApplication} from '../../contexto'
import {ObtenerClasificacionPorId} from '../../cache/servicios-cache/ClasificacionServicios'
import {useHistory} from 'react-router-dom'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {AgregarCupsSACache} from '../../cache/servicios-cache'

const CrearServicio = () => {
  const contextoServicio = useContext(ContextoServicio)

  const contextoAplicacion = useContext(ContextApplication)

  const history = useHistory()

  useEffect(() => {
    contextoServicio.setModoEdicion(false)
    contextoServicio.setInformacionServicio({nombreServicio: ''})
    if (contextoServicio.modoEdicion) {
      history.go(0)
    }
  }, [])

  useEffect(() => {
    const obtenerDatosCups = async () => {
      await AgregarCupsSACache()
    }
    obtenerDatosCups()
  }, [])

  const CrearServicioNuevo = async () => {
    const payloadServicio = {
      cupsId: contextoServicio.cupsInformacion.Id,
      codigoInterno: contextoServicio.informacionServicio.codigoInterno,
      nombre: contextoServicio.informacionServicio.nombreServicio.toUpperCase(),
      cuentaIngresoId: contextoServicio.informacionServicio.cuentaIngreso,
      tiempoDuracion:
        contextoServicio.informacionServicio.tiempoDuracion === undefined
          ? 0
          : contextoServicio.informacionServicio.tiempoDuracion,
      iva: contextoServicio.informacionServicio.iva
        ? contextoServicio.informacionServicio.iva
        : 0,
      clasificacion: (
        await ObtenerClasificacionPorId(
          parseInt(contextoServicio.informacionServicio.clasificacionServicio)
        )
      ).Nombre,
      clasificacionId:
        contextoServicio.informacionServicio.clasificacionServicio,
      grupoLaboratorioId: contextoServicio.informacionServicio.grupoLaboratorio
        ? contextoServicio.informacionServicio.grupoLaboratorio
        : null,
      siglaSticker: contextoServicio.informacionServicio.siglaSticker
        ? contextoServicio.informacionServicio.siglaSticker.toUpperCase()
        : '',
      observaciones:
        contextoServicio.informacionServicio.observacionesPreparacion ===
        undefined
          ? null
          : contextoServicio.informacionServicio.observacionesPreparacion.toUpperCase(),
    }

    CrearNuevoServicio(payloadServicio)
      .then((res) => {
        if (res.status === 200) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: 'SERVICIO CREADO',
            contenido: (
              <>
                <span className="w-full text-cendiatra">ID: {res.data}</span>
                <span className="w-full text-cendiatra">
                  CÓDIGO INTERNO SERVICIO:{' '}
                  {contextoServicio.cupsInformacion.Codigo +
                    '-' +
                    contextoServicio.informacionServicio.codigoInterno}
                </span>
                <span className="w-full text-cendiatra">
                  SERVICIO:{' '}
                  {contextoServicio.informacionServicio.nombreServicio.toUpperCase()}
                </span>
              </>
            ),
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
              {
                nombre: 'Crear Nuevo',
                click: manejarCrearNuevoServicio,
              },
            ],
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })

    const manejarCrearNuevoServicio = () => {
      history.go(0)
    }
    const manejarFinalizar = () => {
      contextoAplicacion.setModal({abierto: false, botones: []})
      history.push('/main')
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.EJECUTIVO_COMERCIAL.CREAR_SERVICIO}
    >
      <div className="w-full flex justify-center items-center">
        <FormularioCrearServicio clickGuardar={CrearServicioNuevo} />
      </div>
    </ContenedorPagina>
  )
}

export default CrearServicio
