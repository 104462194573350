import React, {useContext, useEffect, useState} from 'react'
import {Input} from '../../inputs'
import {useForm, Controller} from 'react-hook-form'
import {regexBasicoV4, regexCodigoCUPS} from '../../../constantes/regex'
import {AlertaDinamica} from '../../MostrarInformacion'
import clsx from 'clsx'
import {ValidarCupsPorNombreOCodigoExacto} from '../../../cache/servicios-cache'
import {TEXTO_VISUAL} from '../../../constantes'
import {BotonSimple} from '../../Botones'
import {CrearCup} from '../../../microservicios'
import {ContextApplication} from '../../../contexto'
import {useHistory} from 'react-router-dom'

const FormularioCrearCups = () => {
  const {
    control,
    setValue,
    trigger,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  })

  const [mostrarAlertaCups, setMostrarAlertaCups] = useState(false)
  const [mostrarAlertaNombre, setMostrarAlertaNombre] = useState(false)

  const [mensajeAlerta, setMensajeAlerta] = useState('')

  const contextoAplicacion = useContext(ContextApplication)

  const history = useHistory()

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  useEffect(() => {
    if (!watch('codigoCups')) {
      resetearAlerta(true)
      trigger()
    }
  }, [watch('codigoCups')])

  const resetearAlerta = (esCups) => {
    esCups ? setMostrarAlertaCups(false) : setMostrarAlertaNombre(false)

    if (!(mostrarAlertaNombre || mostrarAlertaCups)) {
      setMensajeAlerta('')
    }
  }

  const validarCodigoCups = async (codigoONombre, esCups) => {
    if (!codigoONombre) {
      resetearAlerta(esCups)
      return
    }
    const respuesta = await ValidarCupsPorNombreOCodigoExacto(codigoONombre)
    if (respuesta.length) {
      return esCups ? setMostrarAlertaCups(true) : setMostrarAlertaNombre(true)
    }
    trigger()
    resetearAlerta(esCups)
  }

  useEffect(() => {
    const manejarTextoAlerta = () => {
      if (mostrarAlertaCups && mostrarAlertaNombre) {
        return setMensajeAlerta(
          'El código CUPS y el nombre ya se encuentra registrado en la base de datos'
        )
      }
      return setMensajeAlerta(
        mostrarAlertaCups
          ? 'El código CUPS ya se encuentra registrado en la base de datos'
          : 'El nombre del código CUPS ya se encuentra registrado en la base de datos'
      )
    }

    manejarTextoAlerta()
  }, [mostrarAlertaCups, mostrarAlertaNombre])

  const formatearInput = (valorInput) => {
    if (!valorInput) {
      resetearAlerta(true)
    }
    const valorInicial = valorInput.replace(/[^a-zA-Z0-9]/g, '')

    const primerCaracter = valorInicial.charAt(0)
    const otrosNumeros = valorInicial.slice(1).replace(/[^0-9]/g, '')

    const valorFormateado = `${primerCaracter || ''}${otrosNumeros
      .replace(/(\d{1})(\d{1})(\d{1})(\d{2})/, '$1.$2.$3.$4')
      .slice(0, 8)}`
    setValue('codigoCups', valorFormateado)
    if (valorInput.length > 2) {
      validarCodigoCups(valorFormateado, true)
    }
  }

  const confirmarCreacionCups = () => {
    const datos = getValues()
    return contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <div className={`w-full flex justify-center items-center flex-wrap`}>
            <span className="w-full text-center text-2xl font-bold text-cendiatra-rojo-1 mb-10 normal-case">
              SE CREARÁ UN NUEVO CÓDIGO CUPS
            </span>
            <span className="w-full text-center text-md text-cendiatra-rojo-1 mb-10 normal-case">
              ESTA ACCIÓN NO SE PUEDE DESHACER
            </span>
            <span className="w-full text-center text-lg font-bold text-cendiatra mt-5 normal-case">
              POR FAVOR REVISE LA INFORMACIÓN ANTES DE CONTINUAR
            </span>
            <div className="w-full flex justify-center items-center text-center flex-wrap mt-8">
              <span className="font-extrabold w-auto text-cendiatra">
                CÓDIGO CUPS:
              </span>
              <span className="w-auto text-center text-lg text-cendiatra  normal-case ml-1">
                {datos?.codigoCups?.toUpperCase()}
              </span>
            </div>
            <div className="w-full flex justify-center items-center text-center flex-wrap">
              <span className="font-extrabold w-auto text-cendiatra">
                NOMBRE DEL CÓDIGO CUPS:
              </span>
              <span className="w-auto text-center text-lg text-cendiatra  normal-case ml-1">
                {datos?.nombreCups?.toUpperCase()}
              </span>
            </div>
            <span className="w-full text-center font-bold text-lg text-cendiatra mt-5 normal-case">
              ¿DESEA GUARDAR EL NUEVO CÓDIGO?
            </span>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => {
            crearNuevoCups()
            cerrarModal('setModal')
          },
        },
        {
          nombre: 'No',
          click: () => {
            cerrarModal('setModal')
          },
        },
      ],
      clasesAdicioneles:
        'w-1/3 pt-0 pb-0 px-10 bg-cendiatra-gris-5 rounded-2xl',
    })
  }

  const crearNuevoCups = async () => {
    const datos = getValues()

    const payload = {
      codigo: datos?.codigoCups?.toUpperCase(),
      nombre: datos?.nombreCups?.toUpperCase(),
    }

    try {
      const respuesta = await CrearCup(payload)
      if (respuesta.status === 200) {
        return contextoAplicacion.setModal({
          abierto: true,
          titulo: '',
          contenido: (
            <>
              <div
                className={`w-full flex justify-center items-center flex-wrap`}
              >
                <span className="w-full text-center text-2xl font-bold text-cendiatra mb-10 normal-case">
                  Nuevo código CUPS creado
                </span>
                <div className="w-full flex justify-center items-center text-center flex-wrap mt-8">
                  <span className="font-extrabold w-auto text-cendiatra">
                    ID:
                  </span>
                  <span className="w-auto text-center text-lg text-cendiatra  normal-case ml-1">
                    {respuesta.data}
                  </span>
                </div>
                <div className="w-full flex justify-center items-center text-center flex-wrap">
                  <span className="font-extrabold w-auto text-cendiatra">
                    CÓDIGO CUPS:
                  </span>
                  <span className="w-auto text-center text-lg text-cendiatra  normal-case ml-1">
                    {datos?.codigoCups?.toUpperCase()}
                  </span>
                </div>
                <div className="w-full flex justify-center items-center text-center flex-wrap">
                  <span className="font-extrabold w-auto text-cendiatra">
                    NOMBRE DEL CÓDIGO CUPS:
                  </span>
                  <span className="w-auto text-center text-lg text-cendiatra  normal-case ml-1">
                    {datos?.nombreCups?.toUpperCase()}
                  </span>
                </div>
              </div>
            </>
          ),
          botones: [
            {
              nombre: 'Crear Nuevo',
              click: () => {
                cerrarModal('setModal')
                history.go(0)
              },
            },
            {
              nombre: 'Finalizar',
              click: () => {
                cerrarModal('setModal')
                history.push('/')
              },
            },
          ],
          clasesAdicioneles:
            'w-1/3 pt-0 pb-0 px-10 bg-cendiatra-gris-5 rounded-2xl',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-full flex justify-evenly items-center mb-16 mt-5 flex-wrap">
      <Controller
        name="codigoCups"
        control={control}
        rules={{
          required: true,
          pattern: regexCodigoCUPS,
          maxLength: 9,
        }}
        render={({field: {value}}) => (
          <Input
            estilosContenedor={'w-3/12'}
            estilosInput={clsx(
              'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
              Object.keys(errors).find((element) => element === 'codigoCups') ||
                mostrarAlertaCups
                ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                : 'focus:border-cendiatra border-cendiatra '
            )}
            tipo={'text'}
            placeholder={'DESCRIPCIÓN'}
            titulo={'Código CUPS*'}
            onChange={(e) => formatearInput(e.target.value)}
            valor={value}
          />
        )}
      />
      <Controller
        name="nombreCups"
        control={control}
        rules={{
          required: true,
          pattern: regexBasicoV4,
          onChange: (e) => validarCodigoCups(e.target.value),
        }}
        render={({field: {onChange, value}}) => (
          <div className="w-8/12 fles justify-center items-center flex-wrap">
            <Input
              estilosContenedor={'w-full'}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1':
                    Object.keys(errors).find(
                      (element) => element === 'nombreCups'
                    ) || mostrarAlertaNombre,
                  'focus:border-cendiatra border-cendiatra ': !Object.keys(
                    errors
                  ).find((element) => element === 'nombreCups'),
                }
              )}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'Nombre del código CUPS*'}
              onChange={onChange}
              valor={value}
            />
            <span className="text-cendiatra-semaforo-rojo w-full text-13px">
              {errors.nombreCups?.type === 'pattern' &&
                TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V4}
            </span>
          </div>
        )}
      />
      <div className="w-full flex justify-center items-center">
        <AlertaDinamica
          mostrarAlerta={mostrarAlertaCups || mostrarAlertaNombre}
          mensaje={mensajeAlerta}
        />
      </div>
      <div className="w-full flex justify-center items-center mt-14 -mb-10">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={clsx(
            'w-28 h-10 text-white bg-cover bg-center bg-no-repeat rounded-xl text-14px',
            {
              'bg-cendiatra-gris-placeholder pointer-events-none':
                Object.keys(errors).length ||
                mostrarAlertaCups ||
                mostrarAlertaNombre,
              'bg-btnBg': !(
                Object.keys(errors).length ||
                mostrarAlertaCups ||
                mostrarAlertaNombre
              ),
            }
          )}
          funcion={confirmarCreacionCups}
          tipoDeBoton={'button'}
        />
      </div>
    </div>
  )
}

export default FormularioCrearCups
