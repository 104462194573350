import {SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA} from '../../constantes'
import {analizarResolucion} from '../../utilidades'
import {CeldaSemaforizada} from '../Tablas'

export const CeldaResolucionFirmaElectronicaSemaforizada = ({
  resolucion,
  nombreCampo,
  valorMostrar,
  informacionFila,
}) => {
  let color = undefined
  let mostrarSemaforo = true
  if (typeof resolucion === 'object' && typeof nombreCampo === 'string') {
    switch (nombreCampo) {
      case 'numeroResolucion':
        if (informacionFila?.vencida || !informacionFila?.activa) {
          color = SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_VENCIDA
        } else if (informacionFila?.proximaVencer) {
          color =
            SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_PROXIMA_VENCER
        } else if (informacionFila?.activa) {
          color = SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_ACTIVA
        } else {
          color = ''
        }
        break
      case 'fechaFinal':
        if (!informacionFila?.vencida && informacionFila?.proximaVencer) {
          color =
            SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_PROXIMA_VENCER
        } else {
          mostrarSemaforo = false
        }
        break
      case 'numeracionFinal':
        if (informacionFila?.vencida || !informacionFila?.activa) {
          color = SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_VENCIDA
        } else if (informacionFila?.proximaVencer) {
          color =
            SEMAFORO_RESOLUCIONES_FIRMA_ELECTRONICA.RESOLUCION_PROXIMA_VENCER
        } else {
          mostrarSemaforo = false
        }
        break

      default:
        mostrarSemaforo = false
        break
    }
  }

  return (
    <CeldaSemaforizada
      colorSemaforo={color}
      mostrarSemaforo={mostrarSemaforo}
      valorMostrar={valorMostrar}
    />
  )
}
