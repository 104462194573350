import {useContext} from 'react'
import BotonConImagen from '../../../BotonConImagen'
import {Boton, BotonSimple} from '../../../Botones'
import {ContextoRegistro} from '../../../../contexto'
import imprimirVerde from '../../../../imagenes/iconoImprimirVerde.svg'
import imprimirGris from '../../../../imagenes/iconoImprimirGris.svg'
import {useForm} from 'react-hook-form'
import {CLASES_DE_FACTURACION} from '../../../../constantes'

const Botones = ({
  clickGuardar,
  clickComprobante,
  clickComprobanteCredito,
  imprimirComprobante,
  emitirFactura,
  imprimirFacturaBoton,
  deshabilitarComprobante = false,
}) => {
  const contextoRegistro = useContext(ContextoRegistro)

  const {
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  })

  return (
    <>
      <div className="w-full flex justify-center items-center mb-2">
        <Boton
          titulo={'Guardar'}
          funcionCLick={clickGuardar}
          habilitado={
            Object.keys(errors).length === 0 &&
            !contextoRegistro.atencionCreada &&
            !!contextoRegistro.foto &&
            !!contextoRegistro.firma &&
            !!contextoRegistro.examenesSeleccionados.length
          }
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
        />
      </div>
      <div className="w-full flex justify-center items-center my-16 ">
        <div className="w-3/12 flex items-center justify-center flex-wrap">
          <BotonSimple
            texto={'Emitir comprobante de atención'}
            funcion={clickComprobante}
            estilosBoton={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              deshabilitarComprobante
                ? 'w-48 h-12 text-white bg-cendiatra-gris-4 rounded-lg m-1 mb-5'
                : 'w-48 h-12 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg text-14px m-1 mb-5'
            }
            deshabilitado={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton ||
              deshabilitarComprobante
            }
            tipoDeBoton={'button'}
          />
          <BotonConImagen
            imagen={
              contextoRegistro.validacionBoton ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante
                ? imprimirGris
                : imprimirVerde
            }
            estilosContenedor="w-full"
            estilosImagen={'w-8 rounded-full -mt-1'}
            textoAlternativo={'botonImprimir'}
            funcionAEjecutar={imprimirComprobante}
            desabilitado={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante
            }
          />
        </div>

        <div className="w-3/12 flex items-center justify-center flex-wrap">
          <BotonSimple
            texto={'Emitir factura'}
            funcion={emitirFactura}
            estilosBoton={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO
                ? 'w-48 h-12 text-white bg-cendiatra-gris-4 rounded-lg m-1 mb-5'
                : 'w-48 h-12 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg text-14px m-1 mb-5'
            }
            deshabilitado={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton
            }
            tipoDeBoton={'button'}
          />
          <BotonConImagen
            imagen={
              contextoRegistro.validacionBoton ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !contextoRegistro.emitioFactura
                ? imprimirGris
                : imprimirVerde
            }
            estilosContenedor="w-full"
            estilosImagen={'w-8 rounded-full -mt-1'}
            textoAlternativo={'botonImprimir'}
            funcionAEjecutar={imprimirFacturaBoton}
            desabilitado={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !contextoRegistro.emitioFactura
            }
          />
        </div>
        <div className="w-3/12 flex items-center justify-center flex-wrap">
          <BotonSimple
            texto={'Soporte Atención De Crédito'}
            funcion={clickComprobanteCredito}
            estilosBoton={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBotonCredito ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                CLASES_DE_FACTURACION.CREDITO ||
              deshabilitarComprobante
                ? 'w-48 h-12 text-white bg-cendiatra-gris-4 rounded-lg m-1 mb-5'
                : 'w-48 h-12 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg text-14px m-1 mb-5'
            }
            deshabilitado={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBotonCredito ||
              deshabilitarComprobante
            }
            tipoDeBoton={'button'}
          />
          <BotonConImagen
            imagen={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante
                ? imprimirGris
                : imprimirVerde
            }
            estilosContenedor="w-full"
            estilosImagen={'w-8 rounded-full -mt-1'}
            textoAlternativo={'botonImprimir'}
            funcionAEjecutar={imprimirComprobante}
            desabilitado={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante
            }
          />
        </div>
      </div>
    </>
  )
}

export default Botones
