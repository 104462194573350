import Dexie from 'dexie'

export const dbComercial = new Dexie('CendiatraCacheComercial')

dbComercial.version(1).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios: 'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(2).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios: 'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(3).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios: 'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(4).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios: 'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(5).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios: 'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(6).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(7).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbComercial.version(8).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
})

dbComercial.version(9).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones',
})

dbComercial.version(10).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones',
})

dbComercial.version(11).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, MotivoDesactivacion, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones',
})

dbComercial.version(12).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, MotivoDesactivacion, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones',
  UsuariosPortal: 'Guid, Correo, UserPrincipalName, Nombres',
})

dbComercial.version(13).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, MotivoDesactivacion, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones',
  UsuariosPortal: 'Guid, Correo, UserPrincipalName, Nombres, Cargo, EmpresaId',
})

dbComercial.version(14).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, MotivoDesactivacion, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo, CiudadId',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones',
  UsuariosPortal: 'Guid, Correo, UserPrincipalName, Nombres, Cargo, EmpresaId',
})

dbComercial.version(15).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, MotivoDesactivacion, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo, CiudadId',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones, NumeroVersiones',
  UsuariosPortal: 'Guid, Correo, UserPrincipalName, Nombres, Cargo, EmpresaId',
})

dbComercial.version(16).stores({
  Empresas:
    'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, MotivoDesactivacion, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono, Correo, CiudadId',
  Tarifarios: 'Id, EmpresaId, Nombre, FechaInicial, FechaFinal',
  Servicios:
    'Id, CodigoInterno, Nombre, CupsId, CodigoCompleto, Iva, ClasificacionId, SiglaSticker',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  Portafolios:
    'Id, Nombre, FechaCreacion, FechaModificacion, EmpresasAsignado, FechaInicial, FechaFinal',
  FuentesDeDatos: 'Id, Nombre, Opciones, NumeroVersiones',
  UsuariosPortal: 'Guid, Correo, UserPrincipalName, Nombres, Cargo, EmpresaId',
  Cups: 'Id, Codigo, Nombre',
})

// ..
// Agregar versionamiento en esta sección si se desea hacer cambios en el esquema
// ..

dbComercial.open().catch(function (err) {
  console.error('Fallo al abrir el cache comercial: ' + (err.stack || err))
})
