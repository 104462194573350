import {getRestApi} from './ApiAxios'

export const ObtenerCups = async (fechaUltimaActualizacion) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'gato-api-function/ObtenerCups?fecha=' + fechaUltimaActualizacion
  )
}

export const CrearCup = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'put',
    url: 'gato-api-function/CrearCup',
    data: payload,
  })
}
