/** Rutas utilizadas internamente en la aplicación. */
export const RUTAS_APP = {
  AUTORIZACION: {
    ACCESO_DENEGADO: '/accesodenegado',
  },
  COMERCIAL: {
    CREAR_SERVICIO: '/services',
    EDITAR_SERVICIO: '/editarservicio',
    CREAR_PAQUETES: '/crear-paquete',
    EDITAR_PAQUETE: '/editar-paquete',
  },
  PORTAFOLIO: {
    RAIZ: '',
    CREAR: '/crearportafolio',
    EDITAR: '/editarportafolio',
  },
  BACTERIOLOGIA: {
    RESULTADOS_EXAMEN: '/registrarresultados',
    TOMA_DE_MUESTRAS: '/tomademuestras',
  },
  RECEPCION: {
    ASIGNAR_TURNO: '/asignarturno',
    LLAMAR_PACIENTE: '/llamarpaciente',
    REGISTRAR_PACIENTE: '/registrarPaciente',
    AGENDA_REGISTRO: '/agendaregistro',
    MODIFICAR_PACIENTES: '/modificarpacientes',
    MODIFICAR_ATENCION_EJECUTADA: '/modificaratencionejecutada',
    CIERRE_DE_CAJA: '/cierrecaja',
    MODIFICAR_CANCELAR_ATENCION_EMPRESA_HIJA_CARGO:
      '/modificarocancelaratencion',
    RECARGAR_SALDO_EN_SEDE: '/recarga-saldo-en-sede',
    CONSULTA_TARIFARIO_PARTICULAR: '/consulta-tarifario-particular',
    FINALIZAR_RECARGA: '/finalizar-recarga',
    GENERADOR_DE_INFORMES: '/generar-informes',
    HISTORIA_CLINICA: '/historiaclinica',
  },
  RESOLUCIONES_FIRMA_ELECTRONICA: {
    RAIZ: '/resolucionesfirmaelectronica',
    CARGAR: '/cargarresolucionfirmaelectronica',
    MODIFICAR: '/modificarresolucionfirmaelectronica',
  },
  GESTION_FACTURA_ELECTRONICA: '/gestionfacturaelectronica',
  GESTION_FACTURACION: {
    RAIZ: '',
    FACTURAS_PENDIENTES_CORRECCION_SEDE: '/facturaspendientescorreccionsede',
    CORRECCION_ERRORES_TRANSMISION_FACTURA_ELECTRONICA:
      '/correccionerrorestransmisionfacturaelectronica',
    CONSULTA_ESTADO_FACTURACION: '/consultaestadodefacturacion',
  },
  ADMINISTRADOR_GATO: {
    GESTION_ROLES: '/gestionroles',
    EDITAR_ROL: '/editarrol',
    GESTION_FIRMA: '/gestionfirma',
    EDITAR_FIRMA: '/editarfirma',
    MOTOR_DE_PLATILLAS: {
      CREAR_PLANTILLA: '/crearplantilla',
      ASIGNAR_Y_VER_MODIFICAR_PLANTILLA:
        '/gestion-asignacion-plantilla-a-servicio',
      CONTROL_VERSION_DE_PLANTILLAS: '/control-version-de-plantillas',
      CONTROL_VERSION_PLANTILLA: '/control-version-plantilla',
      CREAR_EDITAR_FUENTE_DATOS: '/crear-editar-fuente-datos',
    },
    GESTION_DE_REPORTES: '/gestion-reportes',
    GESTION_DE_REPORTES_NUEVO: '/gestion-reportes-nuevo',
    CREAR_REPORTES: '/crear-reportes',
  },
  ADMINISTRADOR_CONTABLE: {
    CREAR_NOTA_CREDITO: '/crearnotacredito',
  },
  AUX_CONTABLE: {
    CONTABILIDAD: '/contabilidad',
  },
  PROFESIONAL_SST: {
    INFORME_CONDICIONES_DE_SALUD: '/informe-condiciones-de-salud',
  },
  JEFE_DE_SEDE: {
    HABILITAR_MODIFICAR_EXAMENES_ATENDIDOS: '/modificarexamenesatendidos',
    VER_MODIFICAR_EXAMENES_ATENDIDOS: '/verexamenesatendidos',
    REPORTE_EJECUCION_DIARIA_POR_SEDE: '/reporteejecuciondiariaporsede',
    RESUMEN_POR_ENTIDAD: '/resumenporentidad',
    RESUMEN_TRANSACCION_MODIFICAR: '/resumentransaccionmodificar',
    UNIFICAR_DATOS_PACIENTE: '/unificar-datos-paciente',
  },
  MAIN: '/main',
  HOME_APP: '/',
  GERENTE_GENERAL: {
    REPORTE_DE_VENTAS: '/reporte-de-ventas',
    VER_REGENERAR_CIERRE_CAJA: '/ver-regenerar-cierre-caja',
  },

  COMPARTIDO: {
    VER_MODIFICAR_EXAMENES_ATENDIDOS_SOLICITUDES:
      '/ver-examenes-atendidos-para-solicitudes',
    SOLICITAR_MODIFICAR_EXAMENES: '/solicitar-modificar-examenes',
    REPORTE_ATENCION_DIARIA: '/reporte-atencion-diaria',
    VER_SALDO_DEL_CLIENTE: '/ver-saldo-portal-cliente',
    CREAR_CUPS: '/crear-cups',
  },
  EJECUTIVA_COMERCIAL: {
    GESTION_USUARIOS_PORTAL_CLIENTE: '/gestionusuariosportalcliente',
    CREAR_USUARIO_PORTAL_CLIENTE: '/creareditarusuarioportalcliente',
    VER_ATENCIONES: '/veratencionesejecutivocomercial',
    VER_DETALLE_DE_ATENCION: '/ejecutivocomercial/detalleAtencion',
  },
  MEDICO: {
    AGENDA_MEDICO: '/agendamedico',
    VER_EXAMENES_ATENDIDOS_PARA_SOLICITUDES:
      '/ver-examenes-atendidos-para-solicitudes',
  },

  TESORERO: {
    CARGA_ARCHIVOS_BANCOS: '/carga-archivos-bancos',
  },

  GERENTE_COMERCIAL: {
    GESTION_OTROS_SERVICIOS: '/gestion-otros-servicios',
  },
  CONFIRMACION_PAGOS: {
    CONFIRMACION_DE_PAGOS: '/confirmacion-de-pagos',
    FACTURACION_CONFIRMACION_DE_PAGOS: '/facturacion-confirmacion-de-pagos',
    CONTABILIDAD: '/contabilidad',
  },
  CONTROL_TIQUETERAS: {
    CONTROL_VENTAS_TIQUETERAS: '/control-venta-tiqueteras',
    TIQUETERAS_REGISTRADAS: '/tiqueteras-registradas',
  },
}
