import React, {useEffect} from 'react'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../../constantes'
import {FormularioCrearCups} from '../../../componentes/Administrador/crearCups'
import {AgregarCupsSACache} from '../../../cache/servicios-cache'

const CrearCups = () => {
  useEffect(() => {
    const obtenerDatosCups = async () => {
      await AgregarCupsSACache()
    }
    obtenerDatosCups()
  }, [])
  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MENU_ADMINISTRATOR_GATO.CREAR_CUPS
      }
      estilosContenedor="w-7/12"
    >
      <FormularioCrearCups />
    </ContenedorPagina>
  )
}

export default CrearCups
